import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";

export default function TypeSim({ open, close, send, verifySim, simArray }) {
  const [sim, setSim] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setSim(null);
    close();
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    if (simArray.includes(sim)) {
      window.alert("SIM is already added to allocation");
      setLoading(false);
      return;
    }
    const verifyResult = await verifySim(sim);
    if (verifyResult) {
      setLoading(false);
      send(sim);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Enter SIM Number"
            focused
            required
            value={sim}
            onChange={e => setSim(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            startIcon={<Add />}
            color="primary"
          >
            {loading ? "Loading..." : "Add Sim"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
